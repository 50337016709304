/* stylelint-disable selector-class-pattern */
.splide.is-active {
  padding-bottom: 4rem;
}
.splide.is-active .splide__slide {
  display: flex;
  justify-content: center;
}
.splide.is-active .splide__pagination {
  bottom: 1.5em;
}
.splide.is-active .splide__pagination__page {
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: var(--color-neutral-1100);
}
.splide.is-active .splide__pagination__page.is-active {
  background-color: var(--color-blue-1100);
}
.splide.is-active .splide__pagination__page:hover {
  background-color: var(--color-blue-1100);
}

.hp-comparator {
  background-color: var(--color-blue-200);
  padding-block: var(--space-40);
}
@media (min-width: 768px) {
  .hp-comparator .tabs-component--comparator .tabs-component__tabs {
    background-color: #fff;
    border-radius: var(--radius-main);
  }
}
.hp-comparator .tabs-component--comparator .tabs-component__tab {
  text-transform: uppercase;
}
.hp-comparator .tabs-component--comparator .tabs-component__tab[aria-selected=false] {
  --cta-color-border: var(--color-blue-1100);
}
@media (min-width: 768px) {
  .hp-comparator .tabs-component--comparator .tabs-component__tab[aria-selected=false] {
    --cta-color-border: transparent;
  }
}
@media (min-width: 768px) {
  .hp-comparator .tabs-component--comparator .tabs-component__panel .splide__track {
    padding: 1.5rem;
  }
  .hp-comparator .tabs-component--comparator .tabs-component__panel .splide.is-initialized:not(.is-active) .panel__grid {
    display: grid;
  }
}
@media (min-width: 768px) {
  .hp-comparator .tabs-component--comparator .tabs-component__panel .panel__grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 285px);
  }
}
@media (min-width: 1024px) {
  .hp-comparator .tabs-component--comparator .tabs-component__panel .panel__grid {
    grid-template-columns: repeat(auto-fit, 352px);
  }
}
.hp-comparator .tabs-component--comparator .tabs-component__panel .panel__footer {
  text-align: center;
  padding-block: var(--space-16) var(--space-32);
}
.hp-comparator .tabs-component--comparator .tabs-component__panel .panel__link {
  color: var(--color-blue-800);
}

.comparator-card {
  background-color: #fff;
  border-radius: var(--radius-main);
  box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.16);
  padding: var(--space-24) var(--space-20);
  width: calc(100% - var(--space-20));
  margin: 1rem auto;
  text-align: center;
  height: calc(100% - 2rem);
  display: grid;
  align-items: start;
  grid-template-rows: 350px auto auto;
}
.comparator-card--highlighted {
  border: solid 2px var(--color-brand-900);
  position: relative;
}
.comparator-card .pill {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -0.85rem;
  padding: var(--space-4) var(--space-32);
}
.comparator-card__img {
  height: 210px;
}
@media (max-width: 767px) {
  .comparator-card__img img {
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
  }
}
.comparator-card__content h3 {
  border-bottom: solid 1px var(--color-neutral-600);
  padding-block-end: 1rem;
  margin-block-end: 1rem;
}
.comparator-card__footer {
  margin-top: var(--space-32);
  align-self: self-end;
}
.comparator-card__offer p {
  line-height: 1.35;
}
.comparator-card__offer strong,
.comparator-card__offer b {
  color: var(--color-brand-900);
  font-size: 1.8rem;
  font-weight: 500;
}
.comparator-card__offer small {
  font-size: 0.75rem;
}
.comparator-card__offer i {
  font-style: normal;
  font-weight: 500;
}
.comparator-card h3 {
  color: var(--color-neutral-1400);
  font-size: 1.375rem;
}